.User-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #0e0b13;
    color: #fff;
  }
  
  .pin-input {
    width: 200px;
    padding: 10px;
    margin: 10px;
    font-size: 18px;
  }
   
  .btnprimary {
    background: linear-gradient(82deg,#e1054a 19%,#ce4100);
    color: #f9f9f9;
    border: none;
  }
  
  .dlCygn {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 24px 0px;
    text-align: center;
    width: 350px;
    border-radius: 0.25rem;
    outline: none;
  }
  
  .otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
  
  .otp-input-box {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .otp-input-box input {
    width: 40px;
    height: 60px;
    text-align: center;
    margin: 0 5px;
    font-size: 24px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #747171b4;
    color: #e9e8defa;
  
  }
   


  .bEnvHF {
    backdrop-filter: blur(50px);
    background-color: rgb(49, 52, 62);
    border: 1px solid transparent;
    border-radius: 4px;
    caret-color: rgb(11, 164, 164);
    height: 48px;
    margin: 0px;
    outline: none;
    padding: 7px 12px;
    transition: all 0.1s ease 0s;
    width: 350px;
    color: #fff;

}



.bEnvHFPssw {
  backdrop-filter: blur(50px);
  background-color: rgb(49, 52, 62);
  border: 1px solid transparent;
  border-radius: 4px;
  caret-color: rgb(11, 164, 164);
  height: 48px;
  margin: 0px;
  outline: none;
  padding: 7px 12px;
  transition: all 0.1s ease 0s;
  width: 350px;
  margin-top: 20px;
  color: #fff;
}


.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #0e0b13;
  color: #fceb03;
  font-size: 2rem;
  margin-bottom: 20px;
}	